import BlogDetail from '../views/BlogDetail'
import Layout from '../components/Layout'
import React from 'react'
import SEO from '../components/seo'

const BlogDetailPage = ({ pageContext, location }) => {
  const { blogPost, alternativeArticles } = pageContext

  return (
    <Layout>
      <SEO
        title={blogPost?.Meta?.MetaTitle || blogPost?.Title}
        description={blogPost?.Meta?.MetaDescription || blogPost?.Perex}
      />
      <BlogDetail
        url={location.href}
        alternativeArticles={alternativeArticles}
        blogPost={blogPost}
      />
    </Layout>
  )
}

export default BlogDetailPage
